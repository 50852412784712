export default {
  selectors: {
    backToTopWrapper: '.backtotop__wrapper',
    backToTopWrapperVisible: '.backtotop__wrapper--visible',
    backToTopLink: '.backtotop__link',
    backToTopTrigger: '.backtotop__trigger',
    container: '.root .cmp-container',
  },
  classes: {
    backToTopWrapperVisible: 'backtotop__wrapper--visible',
    aemGrid: 'aem-Grid',
    isInResponsiveContainer: 'is-in-responsive-container',
  },
};
