import { register } from '@netcentric/component-loader';
import config from './batcom-backtotop.config';

class BackToTop {
  constructor(el) {
    this.el = el;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.viewportHeight = null;
    this.init();
  }

  init() {
    this.setRefs();
    this.appendButtonToContent();
    this.appendBackToTopTrigger();
    this.setBackToTopTriggerTop();

    if (this.rootContainerIsResponsive(this.content)) {
      this.el.classList.add(this.classes.isInResponsiveContainer);
    }

    this.setObserver();
  }

  setRefs() {
    this.backToTopWrapper = document.querySelector(this.selectors.backToTopWrapper);
    this.backToTopWrapperVisible = document.querySelector(this.selectors.backToTopWrapperVisible);
    this.BackToTopLink = this.backToTopWrapper.querySelector(this.selectors.backToTopLink);
    this.backToTopTrigger = document.querySelector(this.selectors.backToTopTrigger);
    this.container = document.querySelector(this.selectors.container);
    this.content = this.container.querySelector(':scope > div:not(.experiencefragment)');
  }

  rootContainerIsResponsive(container) {
    return container.firstElementChild.firstElementChild.classList.contains(this.classes.aemGrid);
  }

  appendButtonToContent() {
    this.content.appendChild(this.backToTopWrapper);
    this.backToTopWrapper.classList.add(this.classes.backToTopWrapperVisible);
  }

  appendBackToTopTrigger() {
    this.content.appendChild(this.backToTopTrigger);
  }

  setBackToTopTriggerTop() {
    this.viewportHeight = BackToTop.getViewportHeight();
    this.backToTopTrigger.style.top = `${this.viewportHeight + 1}px`;
  }

  setObserver() {
    const observer = new IntersectionObserver(this.handleBackToTopDisplay.bind(this));
    observer.observe(this.backToTopTrigger);
  }

  handleBackToTopDisplay(entries) {
    if (entries[0].isIntersecting) {
      this.backToTopWrapper.classList.add(this.classes.backToTopWrapperVisible);
    } else {
      this.backToTopWrapper.classList.remove(this.classes.backToTopWrapperVisible);
    }
  }

  static getViewportHeight() {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  }
}

register({ BackToTop });
